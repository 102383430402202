import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/tiger-q1/",
		name: "Tiger-Q1_home",
		component: () => import("../views/Tiger_Q1/Home/index.vue"),
	},
	{
		path: "/tiger-q1/info",
		name: "Tiger-Q1_info",
		component: () => import("../views/Tiger_Q1/Info/index.vue"),
	},
	{
		path: "/tiger-q1/list",
		name: "Tiger-Q1_list",
		component: () => import("../views/Tiger_Q1/List/index.vue"),
	},
	{
		path: "/heineken0-0-q1/",
		name: "Heineken0-0-Q1_home",
		component: () => import("../views/Heineken0-0_Q1/Home/index.vue"),
	},
	{
		path: "/heineken0-0-q1/info",
		name: "Heineken0-0-Q1_info",
		component: () => import("../views/Heineken0-0_Q1/Info/index.vue"),
	},
	{
		path: "/heineken0-0-q1/list",
		name: "Heineken0-0-Q1_list",
		component: () => import("../views/Heineken0-0_Q1/List/index.vue"),
	},
	{
		path: "/tiger-q2/",
		name: "Tiger-Q2_home",
		component: () => import("../views/Tiger_Q2/Home/index.vue"),
	},
	{
		path: "/tiger-q2/info",
		name: "Tiger-Q2_info",
		component: () => import("../views/Tiger_Q2/Info/index.vue"),
	},
	{
		path: "/tiger-q2/list",
		name: "Tiger-Q2_list",
		component: () => import("../views/Tiger_Q2/List/index.vue"),
	},
	{
		path: "/edelweiss-q2/",
		name: "Edelweiss-q2_home",
		component: () => import("../views/Edelweiss-Q2/Home/index.vue"),
	},
	{
		path: "/edelweiss-q2/info",
		name: "Edelweiss-q2_info",
		component: () => import("../views/Edelweiss-Q2/Info/index.vue"),
	},
	{
		path: "/edelweiss-q2/list",
		name: "Edelweiss-q2_list",
		component: () => import("../views/Edelweiss-Q2/List/index.vue"),
	},
	{
		path: "/tiger-q3/",
		name: "Tiger-Q3_home",
		component: () => import("../views/Tiger_Q3/Home/index.vue"),
	},
	{
		path: "/tiger-q3/info",
		name: "Tiger-Q3_info",
		component: () => import("../views/Tiger_Q3/Info/index.vue"),
	},
	{
		path: "/tiger-q3/list",
		name: "Tiger-Q3_list",
		component: () => import("../views/Tiger_Q3/List/index.vue"),
	},
	{
		path: "/heineken-cvs-q3/",
		name: "Heineken-cvs-Q3_home",
		component: () => import("../views/Heineken-cvs-Q3/Home/index.vue"),
	},
	{
		path: "/heineken-cvs-q3/info",
		name: "Heineken-cvs-Q3_info",
		component: () => import("../views/Heineken-cvs-Q3/Info/index.vue"),
	},
	{
		path: "/heineken-cvs-q3/list",
		name: "Heineken-cvs-Q3_list",
		component: () => import("../views/Heineken-cvs-Q3/List/index.vue"),
	},
	{
		path: "/heineken-superfast-q4/",
		name: "Heineken-sfast-Q4_home",
		component: () => import("../views/Heineken-sfast-Q4/Home/index.vue"),
	},
	{
		path: "/heineken-superfast-q4/info",
		name: "Heineken-sfast-Q4_info",
		component: () => import("../views/Heineken-sfast-Q4/Info/index.vue"),
	},
	{
		path: "/heineken-superfast-q4/list",
		name: "Heineken-sfast-Q4_list",
		component: () => import("../views/Heineken-sfast-Q4/List/index.vue"),
	},
	{
		path: "/tiger-25-q1/",
		name: "Tiger-25-Q1_home",
		component: () => import("../views/Tiger_25_Q1/Home/index.vue"),
	},
	{
		path: "/tiger-25-q1/info",
		name: "Tiger-25-Q1_info",
		component: () => import("../views/Tiger_25_Q1/Info/index.vue"),
	},
	{
		path: "/tiger-25-q1/list",
		name: "Tiger-25-Q1_list",
		component: () => import("../views/Tiger_25_Q1/List/index.vue"),
	},
	{
		path: "/tiger-25-q1-2/",
		name: "Tiger-25-Q1-2_home",
		component: () => import("../views/Tiger_25_Q1-2/Home/index.vue"),
	},
	{
		path: "/tiger-25-q1-2/info",
		name: "Tiger-25-Q1-2_info",
		component: () => import("../views/Tiger_25_Q1-2/Info/index.vue"),
	},
	{
		path: "/tiger-25-q1-2/list",
		name: "Tiger-25-Q1-2_list",
		component: () => import("../views/Tiger_25_Q1-2/List/index.vue"),
	},
	// {
	// 	path: "/:pathMatch(.*)*",
	// 	redirect: "/",
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
